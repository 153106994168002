<template>
    <div class="header-wrapper">
       <div class="flex-bt container">
           <div class="left">
               <div class="locale-changer">
                   <el-dropdown>
                        <span class="el-dropdown-link white">
                            {{locale}}
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-for="item in locales" :key="item.value">
                                <span @click="changeLanguage(item.value)"> {{item.label}}</span>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                   </el-dropdown>
                   <span>&nbsp;&nbsp;</span>
                   <template v-if="showCurrency === true">
                       <el-dropdown>
                           <span class="el-dropdown-link white">
                               {{currency}}
                           </span>
                           <el-dropdown-menu slot="dropdown">
                               <el-dropdown-item v-for="item in currencies" :key="item.value">
                                   <span @click="changeCurrency(item.value)"> {{item.label}}</span>
                               </el-dropdown-item>
                           </el-dropdown-menu>
                       </el-dropdown>
                   </template>
               </div>
           </div>
           <div class="right flex-end">
               <div 
                   v-for="(item, index) in config" 
                   v-if="item.show !== false"
                   @click="handleClick(item)" 
                   :class="['items', item.class ? item.class : '']" 
                   :key="index"
               >
                   <i v-if="item.icon" class="iconfont" v-html="item.icon"></i>
                   {{$t(item.locate)}}
               </div>
           </div>
       </div>
    </div>
</template>
<script>
import i18n from '@/i18n'; 

export default {
    name: 'Header',
    data() {
      const uniacid = window.localStorage.getItem('uniacid');
    // Initialize the config array
    let config = [
        { label: this.$t('index'), locate: 'index', path: '../home' },
        { label: this.$t('Login/Logon'), locate: 'Login/Logon', path: '/login' },
        { label: this.$t('Myorders'), locate: 'Myorders', path: '/user', type: '0' },
        { label: this.$t('Mycenter'), locate: 'Mycenter', path: '/user' },
        { label: this.$t('Cart'), locate: 'Cart', path: '/cart', type: 'cart', class: 'active', icon: '&#xe70b;', show: uniacid !== '29' },
    ];

    // Check if 'login@token' exists in localStorage
    if (localStorage.getItem('login@token')) {
    // Find the index of the cart item
    const cartIndex = config.findIndex(item => item.locate === 'Cart');

    // Define the logout item
    const logoutItem = { label: this.$t('logout'), locate: 'logout', path: '../home' };

    if (cartIndex !== -1) {
        // Insert the logout item before the cart item
        config.splice(cartIndex, 0, logoutItem);
    } 
}

    return {
        uniacid: window.localStorage.getItem('uniacid'),
        locale: 'EN',
        locales: [
            { value: "EN", label: "English" },
            // { value: "zh-CN", label: "中文简体" },
            { value: "zh-HK", label: "中文繁體" },
        ],
        currency: 'HKD',
        currencies: [
            { value: "HKD", label: "HKD" },
            { value: "USD", label: "USD" },
        ],
        config: config, // Include config in the returned data object
        userInfo: null,
        showCurrency: false,
        username: null, // Add username data property
    }
},

    created() {
        const locale = window.localStorage.getItem('locale') || this.$i18n.locale;
        this.locale = locale;
        this.$i18n.locale = locale;

        const currency = window.localStorage.getItem('currency');
        if (!currency || currency === null || currency.length < 2) {
            this.currency = 'HKD';
            window.localStorage.setItem('currency', 'HKD');
        } else {
            this.currency = currency;
        }

        var url = window.location.href;
        var dz_url = url.split("#")[0];
        var www_dz_url = dz_url.split("/")[2];
        var centerdomain = www_dz_url.split(".");
        if (centerdomain[0] === 'trillionglory') {
            this.showCurrency = true;
        }
        this.uniacid = window.localStorage.getItem('uniacid');
        
        // Retrieve username from local storage
        this.username = window.localStorage.getItem('username');
        
        // Update config array based on login status
        this.updateConfig();
    },
    methods: {
        handleClick(item) {
            if (item.locate === 'logout') {
                this.confirmLogout();
            } else {
                this.goto(item.path, item.type);
            }
        },
        goto(path, type = '0') {
            if (path == '/login' && this.userInfo) {
                this.setCookie('PHPSESSID', '', -1);
                this.$store.commit('saveUserInfo', null);
                window.localStorage.setItem('fb_user_id', '');
                window.localStorage.setItem('fb_name', '');
                this.$router.push({ name: 'Home' });
            } else {
                this.$router.push({ path, query: { type } });
            }
        },
        setCookie(cname, cvalue, exdays) {
            var d = new Date();
            d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
            var expires = "expires=" + d.toUTCString();
            document.cookie = cname + "=" + cvalue + "; " + expires;
        },
        changeLanguage(value) {
            this.locale = value;
            window.localStorage.setItem('locale', value);
            this.$i18n.locale = value;
            window.location.reload();
},
        changeCurrency(value) {
            this.currency = value;
            window.localStorage.setItem('currency', value);
            window.location.reload();
        },
        confirmLogout() {
          this.$confirm(this.$t('Confirm logout?'), {
                confirmButtonText: this.$t('Confirm'),
                cancelButtonText: this.$t('Cancel'),
                type: 'warning'
            }).then(() => {
                this.setCookie('PHPSESSID', '', -1);
                this.$store.commit('saveUserInfo', null);
                window.localStorage.setItem('fb_user_id', '');
                window.localStorage.setItem('fb_name', '');
                window.localStorage.setItem('login@token', '');
                window.localStorage.setItem('user_id', '');
                window.localStorage.setItem('username', '');
                this.$router.push({ name: 'Home' });

                this.$confirm(this.$t('You have logged out'),  {
                    confirmButtonText: 'OK',
                    showCancelButton: false,
                    type: 'info'
                }).then(() => {
                    window.location.reload(); // Refresh the page after confirming logout
                });
            }).catch(() => {
                // User canceled logout, do nothing
            });
        },
        updateConfig() {
            if (this.username) {
                // If user is logged in, replace the login/logon item with a greeting
                this.config = this.config.map(item => 
                    item.locate === 'Login/Logon' ? 
                    { label: `Hello，${this.username}`, locate: `Hello，${this.username}`, path: '' } : item
                );
            } else {
                // If user is not logged in, ensure the login/logon item is present
                if (!this.config.some(item => item.locate === 'Login/Logon')) {
                    this.config.splice(1, 0, { label: this.$t('Login/Logon'), locate: 'Login/Logon', path: '/login' });
                }
            }
        }
    },
    watch: {
        '$store.state.userInfo': function (info) {
            this.userInfo = info;
            if (this.userInfo) {
                this.config[1].label = this.$t('Logout');
                this.config[1].locate = this.$t('Logout');
            } else {
                this.config[1].label = this.$t('Login/Logon');
            }
            this.updateConfig(); // Update config when userInfo changes
        }
    }
}
</script>



<style lang="scss" scoped>
  .white {
    color: white;
  }

  .header-wrapper {
    position: fixed;
    z-index: 100;
    top: 0;
    height: 40px;
    background-color: #282828;
    font-size: 12px;
    color: #b4b4b4;
    cursor: pointer;

    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .container {
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center; // Align items to the center vertically
      width: 1200px;

      @media (max-width: 1200px) {
        width: 100%;
        padding: 0 10px;
      }
    }

    .right {
        display: flex; // Display the right items in a row
        align-items: center; // Align the right items to the center vertically
        justify-content: center; // Align the right items to the center horizontally
        
        
      .items {
        position: relative;
        padding-left: 8px;
        font-size: 12px;
        color: #b4b4b4;
        white-space: nowrap; // Prevent items from wrapping
        margin: 0 8px; // Add some spacing between the right items

        &::before {
          position: absolute;
          content: " ";
          width: 1px;
          height: 14px;
          background-color: hsla(0, 0%, 100%, 0.11);
          left: 0;
          top: 50%;
          margin-top: -7px;
        }

        &:first-child::before {
          background: none;
        }

        &.active {
          width: 118px;
          height: 40px;
          background: #3c3c3c;
          text-align: center;
          line-height: 35px;
          color: #b4b4b4;
          margin-left: 10px;

          &::before {
            background: none;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .header-wrapper {
      font-size: 10px;

      //
      .container {
      flex-direction: column; // Stack the left and right items vertically
      height: auto;
      padding: 10px 0;
    }
    //

      .left,
      .right {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .locale-changer,
      .right {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }

      .right {
        justify-content: center; // Center the right items horizontally on small screens
        flex-wrap: wrap; // Allow right items to wrap if needed
        .items {
          padding-left: 0;

          &::before {
            display: none;
          }

          &.active {
            height: 35px;
            line-height: 35px;
            width: 100%;
            margin-left: 0;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .header-wrapper {
      height: auto;

      .left,
      .right {
        width: 100%;
      }

      .locale-changer {
        flex-wrap: wrap;

        span,
        .el-dropdown {
          margin-bottom: 10px;
        }
      }
    }
  }
</style>