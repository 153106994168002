<template>
  <div id="app">
    <router-view v-if="isRouterAlive"/>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      isRouterAlive: true
    }
  },
  watch: {
    '$route'(to) {
      if ((to.path === '/home' || to.path === '/') && !localStorage.getItem('hasReloaded')) {
        console.log('123');
        localStorage.setItem('hasReloaded', 'true');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    }
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      })
    }
  }
}
</script>

<style lang='scss'>
  @import '@/assets/sass/app.scss';
</style>
<style lang="css">
    @font-face {
      font-family: 'iconfont';  /* Project id 3188734 */
      src: url('https://at.alicdn.com/t/font_3188734_ur3r7hv4e7q.woff2?t=1646238027805') format('woff2'),
          url('https://at.alicdn.com/t/font_3188734_ur3r7hv4e7q.woff?t=1646238027805') format('woff'),
          url('https://at.alicdn.com/t/font_3188734_ur3r7hv4e7q.ttf?t=1646238027805') format('truetype');
    }
    .iconfont{
        font-family:"iconfont" !important;
        font-size:16px;font-style:normal;
        -webkit-font-smoothing: antialiased;
        -webkit-text-stroke-width: 0.2px;
        -moz-osx-font-smoothing: grayscale;
    }
    body {
		background: #ffffff;
		color: #282828;
		font-size: 14px;
		font-family: "PingFang SC","Helvetica Neue",Helvetica,"Hiragino Sans GB","Microsoft YaHei","微软雅黑,Arial","sans-serif";
  	}
</style>