<template>
    <div class="orderConfirm-wrapper order_confirm">
        <search />
        <div class="w1200 margin-top-20">
            <div class="address">
                <div v-if="uniacid !==24" class="title">{{$t('Shipping address')}}</div>
                <div class="lines">
                    <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABLAAAAAECAYAAACeNca/AAAAAXNSR0IArs4c6QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAEsKADAAQAAAABAAAABAAAAAAdekyeAAABbUlEQVR4Ae2asW3DMBBFSWqGACkzRHprgyCbZASPEHiRjJC4TlYIkD4zWIyPd8WXzcZKw+KpkSCIBPHw+HWimNP5eHj5nae6vNv1pqPW4/fhfr5sW98e51TK9n5TPeanz6t+Ga+Thq9zwAd8cAL4oBzIB3zAB+qHRH0W04B6soHAB3xYvRj4fnMc5AP5sJoYrD8YjoHfF8XGNy2nvZ23Hqcy7bttS+7f7z7cubnUbnvGG6zg20DgAz4EAXxQEOQDPuCDEsAHpUE+4AM+KAF8UBqj58MfRyl5tp+pqv4AAAAASUVORK5CYII="
                    />
                </div>
                <div v-if="uniacid !==24 && uniacid !==29" class="list acea-row row-middle">
                    <div class="item on" v-if="settlement && settlement.address.field.address_id">
                        <div class="default bg-color">{{$t('Default')}}</div>
                        <div class="name line1">{{settlement.address.field.contact}}</div>
                        <div class="phone">{{settlement.address.field.contact_number}}</div>
                        <div class="details line4">{{settlement.address.field.full_address}}</div>
                        <div class="iconfont icon-xuanzhong4 font-color"></div>
                    </div>
                    <div class="item add " @click="dialogVisible = true" v-else>
                        <div class="iconfont">&#xe767;</div>
                        <div class="tip">{{$t('Add new address')}}</div>
                    </div>
                </div>
            </div>
            <div class="wrapper wrapper_1200">
                <div class="title">{{$t('order information')}}</div>
                <div class="order">
                    <div class="list">
                        <div class="item acea-row row-between-wrapper" v-for="(item,index) in settlement.goods.list" :key="index">
                            <div class="txtPic acea-row row-middle">
                                <div class="pictrue">
                                    <img :src="item.goods_image"/>
                                </div>
                                <div class="text">
                                    <div class="name line2">{{ translateGoodsName(item.goods_name)}}  - {{item.color}}, {{item.location}}</div>
                                    <div class="info">{{$t('Default')}}</div>
                                </div>
                            </div>
                            <div>
                                <template v-if="currency === 'USD'">
                                    <span class="money">US${{item.us_price}}</span>
                                </template>
                                <template v-else>
                                    <span class="money">{{item.danwei}}{{item.goods_price}}</span>
                                </template>
                                <span class="num">x{{item.goods_number}}</span>
                                 <span v-if="form.songarrlite[item.goods_id]" class="font-color">送1</span>  
                            </div>
                        </div>
                    </div>
                    <div class="coupon message acea-row">
                        <div class="msgTitle">{{$t('seller message')}}</div>
                        <textarea placeholder="" v-model="form.buyer_remark" maxlength="150" class="textarea"></textarea>
                    </div>
                    <div class="coupon message acea-row flex-start">
                         <div class="msgTitle" style="margin-right:40px">{{$t('coupon')}}</div>
                        <el-select v-model="form.user_coupon_id" @change="changeCoupon" :placeholder="settlement.coupon.field.text">
                            <el-option
                                v-for="item in couponList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="totalCon">
                    <template v-if="currency === 'USD'">
                        <div v-for="(item,index) in form.charges_us" class="total acea-row row-middle row-right" :key="index">
                            <div>
                                {{item.label}}：
                            </div>
                            <div class="money">US${{item.text}}</div>
                        </div>
                    </template>
                    <template v-else>
                        <div v-for="(item,index) in form.charges" class="total acea-row row-middle row-right" :key="index">
                            <div>
                                {{item.label}}：
                            </div>
                            <div class="money"> {{item.text}}</div>
                        </div>
                    </template>
                </div>
                <div class="totalAmount">
                    {{$t('Total payable')}}：
                    <template v-if="currency === 'USD'">
                        <span class="money font-color">US${{parseInt(form.charges_us[0].text) + parseInt(form.charges_us[1].text) + parseInt(form.charges_us[1].text)}}</span>
                    </template>
                    <template v-else>
                        <span class="money font-color">{{settlement.submit.field.order_amount}}</span>
                    </template>
                </div>
                <div class="bnt acea-row row-right">
                    <div class="submit bg-color" @click="formSubmit">{{$t('Submit Order')}}</div>
                </div>
            </div>
        </div>

        <el-dialog
            title="ADDRESS"
            :visible.sync="dialogVisible"
            width="500px"
            :before-close="handleClose">
            <div class="form">
    <el-form v-model="form">
        <el-input class="margin-bottom-30" :placeholder="$t('Please enter name')" v-model="form.contact" clearable></el-input>
        <el-input class="margin-bottom-30" :placeholder="$t('Please enter contact number')" v-model="form.contact_number" clearable></el-input>
        <el-input class="margin-bottom-30" :placeholder="$t('Please enter shipping address')" v-model="form.address" clearable></el-input>
        <el-input class="margin-bottom-30" :placeholder="$t('Please enter house number')" v-model="form.house_number" clearable></el-input>
        <el-switch v-model="form.is_default" :active-text="$t('Set as default address')">
        </el-switch>
    </el-form>
</div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">{{ $t('Cancel') }}</el-button>
            <el-button type="primary" @click="orderFormSubmit">{{ $t('Confirm') }}</el-button>
        </span>
        </el-dialog>
    </div>
</template>

<script>
import Search from "@/components/Search";
//  import translations from "./translation.json";
import translations from "@/assets/translations.json";

export default {
    name: "orderConfirm",
    components: { Search },
    data() {
        return {
            form: {
                user_coupon_id: "",
                address_id: "",
                integral: "",
                cart_id: "",
                delivery_time: "",
                buyer_remark: "",
                youhuima: "",
                songallcount: 0,
                songarr: [],
                charges: [],
                charges_us: [],
                songarrlite: {}
            },
            dialogVisible: false,
            addressForm: {
                contact: '',
                contact_number: '',
                address: '',
                house_number: '',
                is_default: true,
                province: '',
                city: '',
                district: '',
                longitude: '',
                latitude: '',
                address_id: '',
                region: []
            },
            couponList: [],
            settlement: null,
            currency: window.localStorage.getItem('currency'),
            uniacid: null,
            locale: window.localStorage.getItem('locale') || 'EN'
        };
    },
    created() {
        this.form.cart_id = this.$route.params.cart_id || ''
        this.getsettlement();
        const webinfo = JSON.parse(window.localStorage.getItem('webinfo') || '{}');
        this.uniacid = webinfo.uniacid;
    },

    methods: {
        changeCoupon() {
            this.getsettlement()
        },
        translateGoodsName(goodsName) {
            if (this.locale === 'EN') {
                return translations[goodsName] || goodsName; // Use translations from JSON
            }
            return goodsName; // Return original name if locale is not 'EN'
        },
        // 获取结算信息
        async getsettlement() {
            const res = await this.$http.get("/settlement", this.form);
            if (res.code !== 0) {
                this.$store.dispatch('global/handleMessage', { message: res.message });
            }
            this.settlement = res.data;
            this.form.songallcount = this.settlement.songallcount;
            this.couponList = this.settlement.coupon.list.map(item => {
                item.label = '满' + item.minimum + '减' + item.discount
                item.value = item.user_coupon_id
                return item
            })
            this.form.songarrlite = this.settlement.songarrlite
            this.form.charges = this.settlement.charges
            this.form.charges_us = this.settlement.charges_us
        },
        // 提交订单
        async formSubmit() {
            var ss = window.localStorage.getItem('webinfo');
            var ssarray = JSON.parse(ss)
            var uniacid = ssarray.uniacid
            let form = this.form
            for (let i in this.settlement) {
                form = { ...form, ...this.settlement[i].form }
            }
            let cartIdList = []
            this.settlement.goods.list.forEach(item => {
                cartIdList.push(item.cart_id)
            })
            form.cart_id = cartIdList.join(',')
            form.uniacid = uniacid
            form.order_currency = this.currency
            const res = await this.$http.post('/settlement/submit', form)
            if (res.code != 0) {
                this.$store.dispatch('global/handleMessage', { message: res.message });
            }
            this.$router.push({ name: 'User' })
        },
        // 提交
        async orderFormSubmit() {
            let form = this.addressForm
            form.is_default = Number(form.is_default)
            let region = form.region || []
            form.province = region.shift() || ''
            form.city = region.shift() || ''
            form.district = region.shift() || ''
            const api = '/user.address/' + ('add')
            const res = await this.$http.post(api, form)
            if (res.code == 0) {
                this.dialogVisible = false
                this.getsettlement()
            } else {
                this.$store.dispatch('global/handleMessage', { message: res.message });
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.orderConfirm-wrapper {
}
.input-item {
    margin-bottom: 20px;
}
.text-wrapper {
    position: relative;
    height: 40px;
    line-height: 40px;
    border: 1px solid #dcdfe6;
    padding: 0 15px;
    box-sizing: border-box;
    border-radius: 4px;
    color: #cfcfcf;
    .select-wrapper {
        z-index: 10;
        position: absolute;
        left: 0;
        top: 45px;
        width: 100%;
        padding: 0 15px;
        background: #fff;
        border: 1px solid #e93323;
        border-radius: 4px;
        line-height: 2;
        .title-box {
            height: 40px;
            line-height: 40px;
            border-bottom: 1px solid #efefef;
            color: #e93323;
            font-size: 14px;
            span {
                margin-right: 8px;
                color: #666666;
            }
        }
        .label-txt {
            margin: 8px 0 18px;
            color: #666666;
            font-size: 14px;
            span {
                margin-right: 10px;
                cursor: pointer;
                &.on {
                    color: #e93323;
                }
            }
        }
    }
}

 .margin-bottom-30{
        margin-bottom: 20px;
    }
div {
    box-sizing: border-box;
}
.margin-top-20 {
    margin-top: 20px;
}
.order_confirm {
    .header {
        height: 60px;
        line-height: 60px;
        color: #999999;
        .home {
            color: #282828;
        }
    }
    .address {
        background-color: #fff;
        .title {
            height: 64px;
            font-size: 18px;
            padding: 0 28px;
            line-height: 64px;
        }
        .lines {
            width: 100%;
            height: 4px;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .list {
            padding: 0 25px 26px 25px;
            height: 217px;
            overflow: hidden;
            &.on {
                height: auto;
            }
            .item {
                width: 250px;
                height: 170px;
                border: 1px solid #eaeaea;
                padding: 22px 27px;
                overflow: hidden;
                margin: 30px 0 0 30px;
                position: relative;
                cursor: pointer;
                &.on {
                    border-color: #e93323;
                }
                .icon-xuanzhong4 {
                    position: absolute;
                    right: -4px;
                    bottom: -4px;
                    font-size: 27px;
                }
                .default {
                    position: absolute;
                    width: 56px;
                    height: 23px;
                    font-size: 12px;
                    color: #fff;
                    text-align: center;
                    line-height: 23px;
                    top: 0;
                    right: 0;
                }
                &.add {
                    text-align: center;
                    .iconfont {
                        font-size: 35px;
                        color: #bfbfbf;
                        margin-top: 25px;
                    }
                    .tip {
                        color: #c8c8c8;
                        margin-top: 8px;
                    }
                }
                .name {
                    font-size: 16px;
                }
                .phone {
                    margin-top: 9px;
                }
                .details {
                    color: #999;
                    margin-top: 4px;
                    word-break: break-all;
                }
            }
        }
    }
    .isShow {
        width: 100%;
        height: 46px;
        line-height: 46px;
        text-align: center;
        color: #707070;
        cursor: pointer;
        .iconfont {
            margin-left: 8px;
            font-size: 12px;
        }
    }
    .wrapper {
        background-color: #fff;
        padding-bottom: 56px;
        .title {
            height: 64px;
            line-height: 64px;
            padding: 0 28px;
            font-size: 18px;
        }
        .order {
            width: 100%;
            margin: 0 auto;
            border: 1px solid #efefef;
            padding: 26px 32px;
            .list {
                .item {
                    margin-bottom: 26px;
                    .txtPic {
                        .pictrue {
                            width: 62px;
                            height: 62px;
                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .text {
                            width: 342px;
                            margin-left: 10px;
                            .name {
                                width: 100%;
                                height: 38px;
                            }
                            .info {
                                margin-top: 4px;
                                color: #919191;
                            }
                        }
                    }
                    .font-color {
                        font-size: 16px;
                        font-weight: bold;
                        display: inline-block;
                        width: 130px;
                        text-align: right;
                    }
                    .money {
                        font-size: 16px;
                    }
                    .num {
                        margin-left: 6px;
                    }
                }
            }
            .coupon {
                border-top: 1px solid #efefef;
                .couponTitle {
                    font-size: 18px;
                    margin: 20px 0;
                    .couponPrice {
                        font-size: 16px;
                        font-weight: bold;
                    }
                    .couponPriceNo {
                        font-size: 14px;
                    }
                }
                .couponList {
                    .item {
                        height: 40px;
                        width: 176px;
                        border: 1px solid #e93323;
                        margin: 0 15px 15px 0;
                        position: relative;
                        cursor: pointer;
                        &.grey {
                            border-color: #b4b4b4;
                        }
                        .iconfont {
                            position: absolute;
                            right: -2px;
                            bottom: -4px;
                            font-size: 20px;
                        }
                        .name {
                            width: 70px;
                            height: 100%;
                            color: #fff;
                            text-align: center;
                            line-height: 40px;
                            background-color: #e93323;
                            &.grey {
                                background-color: #b4b4b4;
                            }
                        }
                        .money {
                            width: 103px;
                            text-align: center;
                            color: #e93323;
                            &.grey {
                                color: #b4b4b4;
                            }
                        }
                    }
                }
                .integralCurrent {
                    margin-left: 33px;
                    .num {
                        margin-left: 6px;
                    }
                }
                .msgTitle {
                    font-size: 18px;
                }
            }
            .message {
                padding-top: 26px;
                margin-top: 26px;
                .textarea {
                    width: 820px;
                    height: 120px;
                    background-color: #f7f7f7;
                    border: 0;
                    outline: none;
                    resize: none;
                    padding: 12px 14px;
                    margin-left: 26px;
                }
            }
            .integral {
                padding: 26px 0;
            }
        }
        .totalCon {
            padding: 27px 46px;
            .total {
                & ~ .total {
                    margin-top: 12px;
                }
                .money {
                    width: 120px;
                    text-align: right;
                    font-size: 16px;
                }
            }
        }
        .totalAmount {
            width: 100%;
            height: 70px;
            line-height: 70px;
            background: #f7f7f7;
            text-align: right;
            padding-right: 22px;
            margin: 0 auto;
            .money {
                font-size: 20px;
                font-weight: bold;
                margin-left: 4px;
                width: 120px;
                display: inline-block;
            }
        }
        .bnt {
            margin: 38px 20px 0 0;
            cursor: pointer;
            .submit {
                width: 180px;
                height: 46px;
                border-radius: 4px;
                font-size: 16px;
                color: #fff;
                text-align: center;
                line-height: 46px;
            }
        }
        .check-promo-code {
            text-align: center;
            line-height: 40px;
            color: #fff;
            background: #e93323 !important;
            padding-left:10px;
            padding-right:10px;
            cursor: pointer;
        }
    }
}
</style>